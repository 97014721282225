@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --grey: #2e292a;
  --pink: #f4e4c3;
  --blue: #00c2cb;
  --brown: #cb9d74;
  --red: #ff5757;
  --lightPink: #f6e5cf;
  --green: #c9e265;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--grey);
  max-width: 667px;
  margin: 0 auto;
  letter-spacing: 1px;
  color: #f4e4c3;
  padding: 80px 20px 88px;
  font-size: 11px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  letter-spacing: 2px;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}
p {
  margin-top: 0;
  margin-bottom: 11px;
}
a {
  color: var(--brown);
}
a:hover {
  color: var(--blue);
}

/* utility */
button {
  font-family: "Open Sans", sans-serif;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;
}
.btn {
  text-transform: uppercase;
  text-decoration: none;
  padding: 14px 30px;
}
.btn:hover, button:hover{
  text-decoration: underline;
}
.btn-primary {
  background-color: var(--blue);
}
.btnTransparent {
  color: var(--grey);
  text-transform: uppercase;
  background-color: transparent;
}
.alertPopUp {
  position: fixed;
  top: 61px;
  left: 20px;
  text-align: center;
  width: 667px;
  max-width: 90%;
  max-width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--blue);
  z-index: 101;
  align-items: center;
}
.alertPopUp .apuClose {
  cursor: pointer;
  font-weight: 700;
}
.alertPopUp .btnSecondary {
  background-color: #ffffff;
  border: #ffffff;
  color: var(--blue);
  font-size: 10px;
  margin: 5px 16px;
  padding: 5px 16px;
  text-decoration: underline;
}

/* accordion */
.accordion__button {
  background-color: var(--blue);
  padding: 24px 14px;
  color: var(--grey);
  font-weight: 800;
  margin-top: 2px;
  cursor: pointer;
}
.accordion__panel {
  border: 1px solid var(--blue);
  border-width: 0 1px 0;
  padding: 15px;
}
.accordion .accordion__item:last-child .accordion__panel{
  border-bottom-width: 1px;
}
.accordion__panel b,
.deetsTabs b {
  color: var(--blue);
}
.accordion__panel .apTitle {
  color: var(--blue);
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 23px;
  margin-bottom: 15px;
}

/* Navigation */
.navSticky {
  display: flex;
  list-style: none;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 20px;
  position: fixed;
  width: 667px;
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 99;
  background-color: var(--grey);
  text-transform: uppercase;
}
.navSticky li{
  width: 33.33%;
  text-align: center;
}
.navSticky img {
  height: 27px;
  margin-bottom: 13px;
}
.navSticky a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #f4e4c3;
}
.navSticky a.navActive {
  color: var(--blue);
}
.nsAct {
  display: none;
}
a.navActive img {
  display: none;
}
a.navActive .nsAct {
  display: block;
}

/* top Nav */
.topDirNav {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 667px;
  padding: 21px 20px 15px;
  background-color: var(--grey);
  display: flex;
  justify-content: space-between;
}
.topDirNav a {
  width: 32px;
  display: inline-block;
}
.topDirNav .tdnAct,
.topDirNav a:hover .tdnImg,
.topDirNav .tdnLogout .tdnAct,
.topDirNav .tdnLogout:hover .tdnImg {
  display: none;
}
.topDirNav a:hover .tdnAct,
.topDirNav .tdnLogout:hover .tdnAct {
  display: block;
}
/* .topDirNav a:hover .tdnImg {
  display: none;
} */
.topDirNav .tdnLogout{
  display: flex;
}
.topDirNav .tdnLogout img{
  width: 23px;
  align-self: center;
  cursor: pointer;
}

/* Landing image */
.lanImgCon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.lanImgCon img {
  width: 327px;
  max-width: 75%;
}
.lanLogo{
  display: block;
  margin: auto;
  position: fixed;
  top: 35px;
  max-height: 200px;
  max-width: 65%;
  left: 50%;
  transform: translateX(-50%);
}

/* registration */
.regPage > img {
  width: 327px;
  position: fixed;
  top: calc(50% - 45px);
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.15;
}
.regPage form {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  position: relative;
}
.regPage label {
  font-size: 8px;
}
.regPage .rpInputGroup {
  display: flex;
  margin-top: 6px;
  margin-bottom: 5px;
}
.regPage .rpInputGroup p {
  background-color: var(--pink);
  text-transform: uppercase;
  width: 105px;
  padding: 13px 10px;
  color: var(--grey);
  margin: 0;
}
.regPage .rpInputGroup input {
  width: 221px;
  font-style: italic;
  padding: 0 13px;
  border: none;
}
.regPage .btn {
  margin-top: 28px;
  text-decoration: none;
}
.regPage .inpErr {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--red);
  font-weight: 700;
  text-shadow: 0px 1px 3px #000000;
}

/* workshop tabs */
.worShpTab {
  /* margin-top: 37px; */
}
.worShpTab .react-tabs__tab-list,
.dateTabs .react-tabs__tab-list{
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 19px 19px 0;
  color: #504D4D;
  margin: 0;
}
.worShpTab .react-tabs__tab-list{
  width: 627px;
  max-width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: var(--grey);
  left: 0;
  padding-bottom: 19px;
}
.worShpTab .react-tabs__tab,
.dateTabs .react-tabs__tab{
  font-size: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.worShpTab .react-tabs__tab span,
.dateTabs .react-tabs__tab span{
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.worShpTab .react-tabs__tab--selected,
.dateTabs .react-tabs__tab--selected{
  color: var(--blue);
}
.dateTabs .react-tabs__tab sup{
  font-size: 15px;
}
.dateTabs .title{
  font-size: 15px;
  color: var(--blue);
  margin-top: 45px;
  margin-bottom: 15px;
}
.dateTabs h4{
  font-size: 12px;
}
.worShpTab .wstConTab {
  display: flex;
  flex-wrap: wrap;
  margin-left: -11px;
  margin-right: -11px;
}
.wstConTab .wctTime {
  width: 100%;
  padding: 55px 0 24px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  color: var(--lightPink);
}
.wstConTab .wctTime span{
  color: var(--red);
}
.wstConTab .wctTime::before{
  content: '';
  width: 50%;
  height: 1px;
  background-color: var(--brown);
  display: block;
  margin: auto;
  margin-bottom: 50px;
}
.wstConTab .wctTime:first-child::before{
  display: none;
}
.insProCon {
  margin-top: 27px;
  width: 33.33%;
  padding: 0 11px;
  text-align: center;
  position: relative;
}
.wctUnSelected {
  opacity: 0.3;
}
.insProCon .ipcInsImg {
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
}
.insProCon .ipcName {
  margin-top: 0;
  margin-bottom: 5px;
  color: #f4e4c3;
  font-size: 13px;
  text-transform: uppercase;
}
.insProCon .ipcSkills {
  color: var(--brown);
  text-transform: uppercase;
}
.insProCon .ipcSlots {
  color: var(--red);
  margin-bottom: 1px;
}
.insProCon button {
  background-color: transparent;
  border: none;
  color: var(--blue);
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.insProCon:hover button,
.insProCon:hover .ipcName {
  text-decoration: underline;
}
.wctSelected button{
  animation: textAnimate 2s;
}
@keyframes textAnimate{
  0%{
    color: var(--green);
  }
  100%{
    color: var(--blue);
  }
}
.insProCon > button {
  display: none;
}
.insProCon .btnSelected {
  display: block;
  position: absolute;
  top: -15px;
  right: 0;
  width: 35px;
}
.insProCon .buAct {
  display: none;
}
.insProCon .btnSelected:hover .buAct {
  display: block;
}
.insProCon .btnSelected:hover .buTimes {
  display: none;
}
.tabBtnCon{
  margin: 30px auto 15px;
  text-align: center;
}
.tabDay{
  color: var(--green);
}
.btnWork {
  display: block;
  margin: auto;
  font-size: 12px;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  color: var(--red);
  font-weight: 600;
}
/*  */
.react-tabs__tab:focus-visible {
  outline: none;
}

.thankYou{
  display: flex;
  align-items: center;
  background-color: var(--blue);
  padding: 10px 7px;
  color: var(--grey);
  margin-bottom: 11px;
  margin-top: 50px;
}
.thankYou img{
  height: 27px;
}
.thankYou p{
  margin-bottom: 0;
  margin-left: 12px;
}


/* about facilitators */
.aboutFacilitators .afTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.aboutFacilitators .afTitle img {
  width: 21px;
  margin-right: 11px;
}
.facilitators {
  display: flex;
  flex-wrap: wrap;
  margin-top: 41px;
  margin-left: -11px;
  margin-right: -11px;
}
.ficilitator {
  width: 33.33%;
  padding: 0 11px 30px;
  text-align: center;
}
.ficilitator a {
  text-decoration: none;
  color: #fff;
}
.ficilitator h4 {
  margin-top: 10px;
  margin-bottom: 3px;
  font-size: 13px;
  text-transform: uppercase;
  color: #f4e4c3;
}
.ficilitator h4:hover{
  text-decoration: underline;
}
.ficilitator p {
  color: var(--brown);
  margin: 0;
  text-transform: uppercase;
}
.facilitatorCon h4{
  font-size: 13px;
  text-transform: uppercase;
  color: var(--blue);
  margin-top: 35px;
  margin-bottom: 17px;
}
.facilitatorCon h5{
  font-size: 13px;
  margin-top: 17px;
  margin-bottom: 17px;
}
.ficilitator .btnTransparent{
  color: #f4e4c3;
  margin-top: 10px;
  color: var(--red);
}

/* my schedule */
.mySchedule{
  margin-top: -30px;
}
.mySchedule .msTitle {
  color: var(--blue);
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 15px;
}
.mySchedule .msDate {
  width: 100%;
  padding: 0 11px;
}
.mySchedule .msTime {
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 0;
}
.mySchedule .msSchItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue);
  color: var(--grey);
  padding: 8px 13px;
  margin-bottom: 30px;
}
.mySchedule .msiFaci {
  display: flex;
  align-items: center;
}
.mySchedule .msfImg {
  height: 57px;
  margin-right: 7px;
}
.mySchedule .msfImg h5 {
  margin-top: 0;
  margin-bottom: 5px;
}
.mySchedule .confirmp {
  text-align: center;
  color: var(--brown);
  font-size: 10px;
}
.mySchedule .msfName {
  font-size: 10px;
}
.mySchedule .msfSkills {
  font-size: 8px;
}
.mySchedule .msiCheck {
  width: 27px;
}
.mySchedule .facilitators{
  margin-top: 11px;
}
.mySchedule .ficTime{
  color: var(--blue);
  font-size: 11px;
}

/* details page */
  .deetsTabs{
    margin-bottom: 30px;
    margin-top: -67px;
    color: #f4e4c3;
  }
  .deetsTabs .react-tabs__tab-list{
    padding-bottom: 25px;
  }
  .deetsTabs .react-tabs__tab{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0 7px;
    text-align: center;
  }
  .deetsTabs .dtIcons{
    height: 32px;
  }
  .deetsTabs .react-tabs__tab img{
    height: 27px;
  }
  .deetsTabs .react-tabs__tab img:last-child,
  .deetsTabs .react-tabs__tab:hover img{
    display: none;
  }
  .deetsTabs .react-tabs__tab:hover img:last-child{
    display: block;
  }
  .deetsTabs .dtGetLoc{
    height: 50px;
    margin-right: 11px;
  }
  .deetsTabs .dticoImg{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .deetsTabs .dticoImg p{
    margin-bottom: 0;
  }
  .deetsTabs .detImg{
    margin: 25px auto 35px;
  }
  .deetsTabs .deetsCon h4{
    text-align: center;
  }
  .deetsTabs .deetsCon p{
    text-align: justify;
  }

/* youtube */
.video-wrapper {position: relative; padding-bottom: 56.25%; /* 16:9 */  padding-top: 25px;margin-bottom: 35px;margin-top: 25px;}
.video-wrapper iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
.vid-info { width: 50%; padding: .5rem; }
.vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
.vid-info .acronym { display: none; }
}


/* only media below */
@media(min-width:667px){
  body{
    font-size: 13px;
  }
  .navSticky, .topDirNav,
  .worShpTab .react-tabs__tab-list,
  .alertPopUp{
    left: 50%;
    transform: translateX(-50%);
  }
  .insProCon .ipcName,
  .ficilitator h4,
  .facilitatorCon h5,
  .facilitatorCon h4,
  .wstConTab .wctTime{
    font-size: 15px;
  }
  .insProCon .ipcSkills,
  .insProCon .ipcSlots{
    margin-bottom: 5px;
  }
  .dateTabs .title{
    font-size: 19px;
  }
  .dateTabs h4{
    font-size: 14px;
    margin-top: 0;
  }
  .aboutFacilitators .afTitle{
    font-size: 19px;
  }
  .aboutFacilitators .afTitle img{
    width: 25px;
  }
  .lanLogo{
    height: 110px;
    top: 15px;
  }
  .deetsTabs .react-tabs__tab,
  .mySchedule .ficTime{
    font-size: 13px;
  }
}